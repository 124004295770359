import React, { useEffect, useContext } from 'react'

import GlobalContext from '../../context/GlobalContext'

const PageWrapper = ({
  children,
  headerDark = false,
  footerDark = false,
  headerCTA = false,
  hideFooterLogo = true,
  currentPage = 'home'
}) => {
  const gContext = useContext(GlobalContext)

  useEffect(() => {
    gContext.setCurrentPage(currentPage)

    if (headerDark) {
      gContext.goHeaderDark()
    } else {
      gContext.goHeaderLight()
    }

    if (footerDark) {
      gContext.goFooterDark()
    } else {
      gContext.goFooterLight()
    }

    if (headerCTA) {
      gContext.goDisplayHeaderCTA()
    } else {
      gContext.goHideHeaderCTA()
    }

    if (hideFooterLogo) {
      gContext.goHideFooterLogo()
    } else {
      gContext.goDisplayFooterLogo()
    }
  }, [gContext, headerDark, footerDark, headerCTA, hideFooterLogo, currentPage])

  return <>{children}</>
}

export default PageWrapper
